import { createSlice } from '@reduxjs/toolkit'

// Create a slice for filtrosBusqueda
const filtrosBusquedaSlice = createSlice({
  name: 'filtrosBusqueda',
  initialState: null,
  reducers: {
    setReduxFiltrosBusqueda: (state, action) => action.payload
  }
})

// Create a slice for page
const pageSlice = createSlice({
  name: 'page',
  initialState: 1,
  reducers: {
    setReduxPage: (state, action) => action.payload
  }
})

// Create a slice for limit
const limitSlice = createSlice({
  name: 'limit',
  initialState: 10,
  reducers: {
    setReduxLimit: (state, action) => action.payload
  }
})

// Create a slice for countRows
const countRowsSlice = createSlice({
  name: 'countRows',
  initialState: 0,
  reducers: {
    setReduxCountRows: (state, action) => action.payload
  }
})

// Create a slice for cupDatos
const cupDatosSlice = createSlice({
  name: 'cupDatos',
  initialState: [],
  reducers: {
    setReduxCupDatos: (state, action) => action.payload
  }
})

// Export the actions
export const { setReduxFiltrosBusqueda } = filtrosBusquedaSlice.actions
export const { setReduxPage } = pageSlice.actions
export const { setReduxLimit } = limitSlice.actions
export const { setReduxCountRows } = countRowsSlice.actions
export const { setReduxCupDatos } = cupDatosSlice.actions

// Combine the slices into one reducer
const storeCups = {
  reduxFiltrosBusqueda: filtrosBusquedaSlice.reducer,
  reduxPage: pageSlice.reducer,
  reduxLimit: limitSlice.reducer,
  reduxCountRows: countRowsSlice.reducer,
  reduxCupDatos: cupDatosSlice.reducer
}

export default storeCups

